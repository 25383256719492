<template>
  <div class="all_main_wrap">
    <HeaderBox>
      <template #title>
        <span @click="$router.go(-1)">作业管理</span>
         / 学员作业
      </template>
    </HeaderBox>
    <!-- 表格列表 -->
    <div class="table-box">
      <div class="cow-top">
        <p>关联课程：{{detailObj.courseName}}</p>
        <div class="font">
          <span class="h4">作业名称：{{detailObj.homeworkTitle}}。</span>
          <span>提交截至时间：{{detailObj.answerEnddate}}</span>
          <a @click="isHomworkShow = true">作业详情></a>
        </div>
        <div class="status">
          <span class="left">作答情况：</span>
          <div class="data-panel">
            <div class="item color1"><span>{{detailObj.allNum}}</span><p>全部</p></div>
            <div></div>
            <div class="item color2"><span>{{detailObj.readOverNum}}</span><p>已批</p></div>
            <div></div>
            <div class="item"><span>{{detailObj.unreadNum}}</span><p>未批</p></div>
            <div></div>
            <div class="item color3"><span>{{detailObj.unsettledNum}}</span><p>未交</p></div>
          </div>
        </div>
      </div>

      <div class="search-l">
        <div>
          <span>姓名：</span>
          <a-input class="input" v-model="queryParams.userName" placeholder="请输入姓名" />
        </div>
        <div>
          <span>手机号：</span>
          <a-input class="input" v-model="queryParams.mobile" placeholder="请输入手机号" />
        </div>
        <div>
          <span>作业状态：</span>
          <a-select placeholder="请选择作业状态" mode="multiple" v-model="workStatus" allowClear class="input">
            <a-icon slot="suffixIcon" type="caret-down" style="color: #3681ef" />
            <a-select-option :value="0">未提交</a-select-option>
            <a-select-option :value="1">暂存</a-select-option>
            <a-select-option :value="3">已提交</a-select-option>
            <a-select-option :value="5">已驳回</a-select-option>
            <a-select-option :value="7">已审批</a-select-option>
            <a-select-option :value="8">成绩发布</a-select-option>
          </a-select>
        </div>
        <div>
          <span>分值：</span>
          <a-input-number placeholder="最低分" v-model="queryParams.minScore" :min="0" /> ~ <a-input-number placeholder="最高分" v-model="queryParams.maxScore" :min="0" />
        </div>
        <div>
          <span>提交时间：</span>
          <a-range-picker
            v-model="pickerData"
            valueFormat="YYYY-MM-DD HH:mm:ss"
            showTime
          ></a-range-picker>
        </div>
        <div class="search-r">
          <a-button type="primary" class="all_boder_btn" @click="onSearch()">搜索</a-button>
          <a-button type="primary" class="all_boder_btn" :loading="loading" @click="onReleaseAll">全场发布成绩</a-button>
        </div>
      </div>
      <a-table
      class="table-template"
      :loading="loading"
      :rowKey="item=>item.answerId"
      :columns="columns"
      :scroll="{ x: 1200 }"
      :data-source="tableData"
      @change="onPage"
      :pagination="{
        total:total,
        current:queryParams.pageNum,  
        defaultPageSize:queryParams.pageSize, 
        showSizeChanger: true,
        showTotal: function(total, range){
        return `共${total}条`}} ">
        <template slot="index" slot-scope="item, row, i">
          <div style="text-align: center;">
            {{ (queryParams.pageNum - 1) * queryParams.pageSize + i + 1 }}
          </div>
        </template>

        <template slot="status" slot-scope="text">
          <span :class="text == 0 ? '' : text == 1 ? 'color1': text == 3 ? 'color2' : text == 5 ? 'color4' : text == 7 ? 'color2' : text == 8 ? 'color2' : ''">
            {{ text == 0 ? '未提交' : text == 1 ? '暂存': text == 3 ? '已提交' : text == 5 ? '已驳回' : text == 7 ? '已审批' : text == 8 ? '成绩发布' : '' }}
          </span>
        </template>

        <template slot="submitTime" slot-scope="text">
          <span>{{text ? text : '-'}}</span>
        </template>

        <template slot="teacherName" slot-scope="text">
          <span>{{text ? text : '-'}}</span>
        </template>

        <template slot="score" slot-scope="text">
          <span>{{text ? text : '-'}}</span>
        </template>

        <template slot="operation" slot-scope="item">
          <div class="btn_router_link">
            <a @click="onDetail(item.answerId,1)">查看作业</a>
            <span v-show="item.status == 7">|</span>
            <a v-show="item.status == 7" @click="onSingleReleaseAll(item.answerId)">发布成绩</a>
            <span>|</span>
            <a @click="getRecord(item.answerId)">反馈记录</a>
            <span>|</span>
            <a @click="getDelete(item.homeworkConfigId,item.userId)">删除</a>
            <!-- <span>|</span>
            <a >催交</a> -->
          </div>
        </template>
      </a-table>
    </div>
    <!-- 作业详情 -->
    <a-modal v-model="isHomworkShow" align="center" title="作业详情" >
      <div class="homework-detail">
        <h3>{{homeWorkObj.title}}</h3>
        <div v-html="homeWorkObj.content"></div>
        <div class="file-list" v-if="homeWorkObj.attachmentVOList">
          <div class="file-item" title="点击在线查看" @click="openFile(item.url)" v-for="(item,index) in homeWorkObj.attachmentVOList" :key="index">
            <img class="icon" :src="getSuffix(item.name)" alt="">
            <span>{{item.name}}</span>
          </div>
        </div>
      </div>
      <template slot="footer">
        <a-button type="primary" @click="isHomworkShow = false">返回</a-button>
      </template>
    </a-modal>
    <!-- 历史反馈 -->
    <a-modal v-model="isModalShow" align="center" width="700px" title="反馈记录" >
      <div class="all_content_box">
         <a-table
          :loading="loading"
          :rowKey="item=>item.id"
          :columns="columns1"
          :data-source="recordList"
          @change="onRecordPage"
          :pagination="{
            total:recordTotal,
            current:recordNum,
            defaultPageSize:recordSize, 
            showSizeChanger: true,
            showTotal: function(total, range){
            return `共${total}条`}} ">
            <template slot="index" slot-scope="item, row, i">
              <div style="text-align: center;">
                {{ (recordNum - 1) * recordSize + i + 1 }}
              </div>
            </template>

            <template slot="status" slot-scope="item">
              <span>{{item == 1 ? '已提交' : item == 2 ? '已驳回' : item == 3 ? '已批阅' : '-'}}</span>
            </template>

            <template slot="reply" slot-scope="text">
              <span>{{text ? text : '-'}}</span>
            </template>

            <template slot="operation" slot-scope="item">
              <div class="btn_router_link">
                <a @click="onDetail(item.answerId,2,item.id)">查看作业</a>
              </div>
            </template>
          </a-table>
      </div>
      <template slot="footer">
        <a-button type="primary" @click="isModalShow = false">返回</a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
const columns = [
  {
    title: "序号",
    width: "80px",
    align:'center',
    dataIndex: "index",
    scopedSlots: { customRender: 'index' }

  },
  {
    title: "姓名",
    align:'center',
    dataIndex: "userName"
  },
  {
    title: "手机号",
    align:'center',
    dataIndex: "mobile"
  },
  {
    title: "提交时间",
    align:'center',
    dataIndex: "submitTime",
    scopedSlots: { customRender: "submitTime" }
  },
  {
    title: "作业状态",
    align:'center',
    dataIndex: "status",
    scopedSlots: { customRender: "status" }
  },
  {
    title: "批阅人",
    align:'center',
    dataIndex: "teacherName",
    scopedSlots: { customRender: "teacherName" }
  },
  {
    title: "分数",
    align:'center',
    dataIndex: "score",
    scopedSlots: { customRender: "score" }
  },
  {
    title: "操作",
    align:'center',
    width: "230px",
    scopedSlots: { customRender: "operation" }
  }
];

const columns1 = [
  {
    title: "序号",
    width: "80px",
    align:'center',
    dataIndex: "index",
    scopedSlots: { customRender: 'index' }

  },
  {
    title: "时间",
    align:'center',
    dataIndex: "createTime"
  },
  {
    title: "操作记录",
    align:'center',
    dataIndex: "status",
    scopedSlots: { customRender: "status" }
  },
  {
    title: "反馈",
    align:'center',
    dataIndex: "reply",
    scopedSlots: { customRender: "reply" }
  },
  {
    title: "操作",
    align:'center',
    width: "230px",
    scopedSlots: { customRender: "operation" }
  }
];


import HeaderBox from '@/components/HeaderBox.vue'
import { openFile } from '@/unit/fun.js'
export default {
  // 可用组件的哈希表
  components: { HeaderBox },
  // 接收传值
  props: {},
  // 数据对象
  data () {
    return {
      docIcon: require('./icon/icon-doc.png'),
      imgIcon: require('./icon/icon-img.png'),
      txtIcon: require('./icon/icon-txt.png'),
      pdfIcon: require('./icon/icon-pdf.png'),
      mp4Icon: require('./icon/icon-mp4.png'),
      xlsIcon: require('./icon/icon-xls.png'),
      detailObj:{}, // 顶部详情
      isHomworkShow:false,
      homeWorkObj:{},

      columns, // 表头
      columns1,
      tableData:[], // 列表数据
      loading:false, // 列表加载
      total: 0, // 总数量
      workStatus: [],
      queryParams:{
        courseId: this.$route.query.courseId, // 课程id
        configId: this.$route.query.configId,  //配置id,
        userName: '', // 用户名称
        mobile: '', // 手机号
        status: '', // 批阅状态
        minScore:'', // 最低分
        maxScore:'', // 最高分
        startTime:null, // 开始日期
        endTime:null, // 结束日期
        pageNum: 1, // 页码
        pageSize: 10, // 页数
      },
      pickerData:[],
      isModalShow:false,


      /* 反馈记录 */
      recordList:[],
      recordNum:1,
      recordSize:10,
      recordTotal:0,
    }
  },
  // 事件处理器
  methods: {
    // 分页
    onPage(e){
      this.queryParams.pageNum = e.current
      this.queryParams.pageSize = e.pageSize
      this.getManageList()
    },

    // 分页
    onRecordPage(e){
      this.recordNum = e.current
      this.recordSize = e.pageSize
    },

    // 高级检索
    onSearch(){
      this.queryParams.pageNum = 1
      this.getManageList()
    },

    // 学员配置
    onDetail(answerId,type,id){
      this.$router.push({ path: "/admin/Subjective/answerDetail", query: { answerId: answerId,type: type, id:id } })
    },

    
    getRecord(answerId){
      this.loading = true
      this.$ajax({
        method: 'get',
        url:"/hxclass-management/homeworkAnswer/history/list",
        params: {
          answerId: answerId,  //配置id,
        }
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success && res.data.length) {
          this.recordList = res.data
          this.recordTotal = res.data.length
          this.isModalShow = true
        } else {
          this.$message.warning('暂无记录');
          this.recordList = []
          this.recordTotal = 0
        }
      })

    },
    //删除作业
    getDelete(homeworkConfigId,userid){

      let _this = this;
      this.$confirm({
        title: "确认删除该作业？",
        okText: "确认",
        onOk() {
          _this.loading = true;
          _this.$ajax({
            url: '/hxclass-management/homeworkAnswer/deletedHomeworkAnswer?courseId=' + _this.$route.query.courseId+'&homeworkConfigId='+homeworkConfigId+'&userId='+userid,
            method: "POST",
          }).then((res) => {
            _this.loading = false;
            if (res.code == 200 && res.success) {
              _this.$message.success('删除成功');
              _this.getManageList()
            } else {
              _this.$message.warning(res.message);
            }
          })
        },
        onCancel() {},
      });



      
    },
    // 全场发布成绩
    onReleaseAll() {
      let _this = this
      this.$confirm({
        title: '确认是否全场发布成绩?',
        okText: '确认',
        onOk() {
          _this.loading = true;
          _this.$ajax({
            url: '/hxclass-management/homeworkAnswer/grades/publish/all?config=' + _this.$route.query.configId,
            method: "POST",
          }).then((res) => {
            _this.loading = false;
            if (res.code == 200 && res.success) {
              _this.$message.success('发布成功');
              _this.getManageList()
              _this.getDetail()
            } else {
              _this.$message.warning(res.message);
            }
          })
        },
        onCancel() {},
      });
    },

    // 单个发布成绩
    onSingleReleaseAll(answerId) {
      let ids = [answerId]
      let _this = this
      this.$confirm({
        title: '确认是否该学员发布成绩?',
        okText: '确认',
        onOk() {
          _this.loading = true;
          _this.$ajax({
            url: '/hxclass-management/homeworkAnswer/grades/publish?homeworkAnswerIds=' + ids,
            method: "POST",
          }).then((res) => {
            _this.loading = false;
            if (res.code == 200 && res.success) {
              _this.$message.success('发布成功');
              _this.getManageList()
              _this.getDetail()
            } else {
              _this.$message.warning(res.message);
            }
          })
        },
        onCancel() {},
      });
    },

    // 解析文件后缀返回对应图片
    getSuffix(string){
      if(string){
        let lastIndex = string.lastIndexOf('.');
        let suffix = string.substring(lastIndex+1)
        let imgSuffix = ['jpg','png','gif','jpeg']
        let xlsSuffix = ['xls','xlsx']
        let imgIcon = ''
        if(suffix == 'doc' || suffix == 'docx'){
          imgIcon = this.docIcon
        } else if (suffix == 'txt'){
          imgIcon = this.txtIcon
        } else if (suffix == 'pdf'){
          imgIcon = this.pdfIcon
        } else if (suffix == 'mp4'){
          imgIcon = this.mp4Icon
        } else if (imgSuffix.indexOf(suffix) != -1){
          imgIcon = this.imgIcon
        } else if (xlsSuffix.indexOf(suffix) != -1){
          imgIcon = this.xlsIcon
        }

        return imgIcon
      }else{
        return false;
      }
    },
    // 打开文件
    openFile(url){
      openFile(url)
    },

    // 查询顶部数据
    getDetail(){
      this.$ajax({
        method: 'get',
        url:"/hxclass-management/homeworkAnswer/select/course/answer",
        params: {
          courseId: this.$route.query.courseId, // 课程id
          configId: this.$route.query.configId,  //配置id,
        }
      }).then(res => {
        if (res.code == 200 && res.success) {
          this.detailObj = res.data
          this.getHomeworkDetail(res.data.homeworkId)
        }
      })
    },

    // 查询作业详情
    getHomeworkDetail(id){
      this.$ajax({
        method: 'get',
        url:"/hxclass-management/homeworkLibrary/select/libraryData",
        params: {
          homeworkId: id
        }
      }).then(res => {
        if (res.code == 200 && res.success) {
          this.homeWorkObj = res.data
        }
      })
    },

    // 查询列表
    getManageList(){
      this.loading = true
      this.queryParams.startTime = (this.pickerData.length ? this.pickerData[0] : null)
      this.queryParams.endTime = (this.pickerData.length ? this.pickerData[1] : null)
      this.queryParams.status = (this.workStatus.length ? this.workStatus.toString() : '')


      this.$ajax({
        method: 'get',
        url:"/hxclass-management/homeworkAnswer/result/statistics",
        params: this.queryParams
      }).then(res => {
        this.loading = false
        if (res.code == 200 && res.success) {
          this.tableData = res.data.records
          this.total = res.data.total
        } else {
          this.tableData = []
          this.total = 0
          this.$message.error(res.message);
        }
      })
    },
    
  },
  // 生命周期-实例创建完成后调用
  created () {
    this.getManageList()
  },
  // 生命周期-实例挂载后调用
  mounted () {
    this.getDetail()
  },
  // 生命周期-实例销毁离开后调用
  destroyed () { },
  // 计算属性监听
  computed: {},
  // 自定义的侦听器
  watch: {}
}
</script>

<style lang="less" scoped>
.btn{
  margin-right: 24px;
}
.table-box{
  /deep/ .ant-tabs-bar{
    border-bottom:none
  }
}
.color1{
  color: #ff941f;
}
.color2{
  color: #32CD32;
}
.color3{
  color: #FA8072;
}
.color4{
  color: #DC143C;
}
.cow-top{
  margin-top: 20px;
  background: #f9f9f9;
  min-height: 100px;
  padding: 20px;
  border-radius: 10px;
  color: #333333;
  .font{
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
    margin-right: 50px;
    .h4{
      font-size: 18px;
      font-weight: bold;
      color: #666666;
    }
  }
  .status{
    display: flex;
    .left{
      display: inline-block;
      width: 80px;
    }
    .data-panel{
      width: calc(100% - 300px);
      padding: 20px 60px;
      border-radius: 10px;
      background: #ffffff;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .item{
        text-align: center;
        span{
          font-size: 27px;
          font-weight: bold;
        }
        p{
          margin-top: 10px;
          font-size: 14px;
        }
      }
    }
  }
}

  .search-l{
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    >div{
      display: flex;
      align-items: center;
      margin-right: 40px;
      margin-bottom: 20px;
      span{
        white-space:nowrap;
      }
      .input{
        width: 220px;
      }
    }
    .search-r{
      margin-left: 50px;
      .all_boder_btn{
        margin-left: 20px;
      }
    }
  }

.all_content_box{
  text-align: left;
}

.homework-detail{
  text-align: left;
  h3{
    font-size: 16px;
    font-weight: bold;
    color: #666666;
    margin-bottom: 5px;
  }  

  /deep/strong{
    font-weight: bold;
  }
  /deep/em{
    font-style: italic;
  }
  /deep/img{
    max-width: 100%;
  }
  /deep/.remarks{
    max-width: 100%;
    height: auto !important;
    -webkit-line-clamp: 10000 !important;
  }
  /deep/ video{
    width: 100%;
  }
  
  .file-list{
    .file-item{
      border: 1px solid #999;
      margin-top: 10px;
      padding: 10px;
      cursor: pointer;
      .icon{
        width: 45px;
      }
    }
  }
}


.table-template{
  margin-top: 0;
}
/deep/ .ant-modal-footer{
  text-align: center;
}
</style>
